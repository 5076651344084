<template>
  <div class="home">
    <table-acctperiodmain></table-acctperiodmain>
  </div>
</template>

<script>
import tableAcctperiodmain from '@/components/pages/maint/acctperiodmain/tableAcctperiodmain.vue';

export default {
	components: {
		'table-acctperiodmain': tableAcctperiodmain,
	}
};
</script>

<style>

</style>

<template>
      <v-dialog v-model="dialog" persistent max-width="70%">
      <template v-slot:activator="{ on }">
          <app-add-button v-bind:module="'acctperiodmain'"></app-add-button>
        </template>
        <v-form
            ref="form"
            v-model="isvalid"
            lazy-validation
           >
         <v-card title>
            <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                    <v-flex xs12 sm6 md4>
                    <v-select
                     v-model="editedItem.fiscal_year"
                     :items="years"
                     :rules="[v => !!v || 'Please select Fiscal Year']"
                     label="Fiscal Year"
                      data-vv-name="fiscalYear"
                     required
                   ></v-select>
                   </v-flex>
                  <v-flex xs12 sm6 md4>
                      <v-select
                          v-model="editedItem.period_no"
                          :items="periodNo"
                          label="Period No"
                          :rules="[v => !!v || 'Please select Period']"
                          data-vv-name="periodNo"
                          required
                      ></v-select>
                  </v-flex>
                 <v-flex xs12 sm6 md4>
                     <v-select
                          v-model="editedItem.disbursement"
                          :items="disbursement"
                          label="Disbursement Status"
                          data-vv-name="disbursement"
                          required
                          maxlength=1
                      ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                     <v-select
                          v-model="editedItem.general_journal"
                          :items="general_journal"
                          label="General Journal Status"
                          data-vv-name="general_journal"
                          required
                          maxlength=1
                      ></v-select>
                </v-flex>
                 <v-flex xs12 sm6 md4>
                   <v-select
                          v-model="editedItem.cash_receipt"
                          :items="cash_receipt"
                          label="Cash Receipts Status"
                          data-vv-name="cash_receipt"
                          required
                          maxlength=1
                      ></v-select>
                </v-flex>
              </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
              <v-btn color="red darken-1"  text @click="close">Cancel</v-btn>
            </v-card-actions>
           </v-card>
         </v-form>
        </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';
import { format, getYear, getMonth, parseISO } from 'date-fns';

export default {
	components: {
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		dataDollarRate: [],
		nextPageDollarRate: '',
		search: null,
		isvalid: true,
		fiscal_yearRules: [
			v => !!v || 'Fiscal Year is required.',
			// v => (v && v.length < 5) || 'Fiscal Year must be less than 5 numbers',
			// v =>  /^\d{4}$/.test(v) || 'Must be 4 digit',
		],
		period_noRules: [
			v => !!v || 'Period No',
			// v => (v && v.length <= 4) || 'Fiscal Year must be 4 numbers',
		],
		bstat_dmRules: [
			v => !!v || 'Debit Memo Status is required.',
			v => /^[0-1]*$/.test(v) || 'Must be 0 or 1 digit',
		],
		bstat_cpRules: [
			v => !!v || 'Check Prep Status is required.',
			v => /^[0-1]*$/.test(v) || 'Must be 0 or 1 digit',
		],
		bstat_aeRules: [
			v => !!v || 'Adjusting Entry Status is required.',
			v => /^[0-1]*$/.test(v) || 'Must be 0 or 1 digit',
		],
		bstat_crRules: [
			v => !!v || 'Cash Receipts Status is required.',
			v => /^[0-1]*$/.test(v) || 'Must be 0 or 1 digit',
		],
		years: []
	}),
	mounted() {
		// this.$store.dispatch('trxdisb/getTrxdisbs');
		this.$store.dispatch('dollarrate/getDollarrates');
		
		let beg_year = '2022';
		let end_year = new Date().getFullYear() + 1;
		for (var i = end_year; i >= beg_year; i--) {
			this.years.push(i );
		}
	},
	computed: {
		...mapGetters({
			acctperiodmains: 'acctperiodmain/acctperiodmains',
			fiscalYear: 'acctperiodmain/fiscalYear',
			periodNo: 'acctperiodmain/periodNo',
			disbursement: 'acctperiodmain/disbursement',
			cash_receipt: 'acctperiodmain/cashReceipt',
			// bstatAe: 'acctperiodmain/bstatAe',
			general_journal: 'acctperiodmain/generalJournal',
			editedItem: 'acctperiodmain/editedItem',
			defaultItem: 'acctperiodmain/defaultItem',
			dialog: 'acctperiodmain/dialog',
			editedIndex: 'acctperiodmain/editedIndex',
			formTitle: 'acctperiodmain/formTitle',
			valid: 'acctperiodmain/valid',
			isLoading: 'app/isLoading',
			currUser: 'auth/currUser',
			trxdisbs: 'trxdisb/trxdisbs',
			dollarrates: 'dollarrate/dollarrates',
		})
	},
	watch: {
		dollarrates: {
			handler() {
				this.getDataDollarRate();
			}
		}
	},
	methods: {
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('acctperiodmain/setValid',true);
				this.$store.dispatch('acctperiodmain/setDialog',false);
				this.$store.dispatch('acctperiodmain/setEditedIndex','-1');
				this.isvalid = false;
			}, 2000);
		},
		save() {
			this.editedItem.edit_by = this.currUser.id;
			let error = '';
			this.$store.dispatch('acctperiodmain/setValid',this.$refs.form.validate());
      
			this.isvalid = this.$refs.form.validate();
			this.editedItem.edit_by = this.currUser.id;
			delete this.editedItem.created_at;
			delete this.editedItem.updated_at;
			delete this.editedItem.deleted_at;
			if(this.isvalid){
				if (this.editedIndex > -1) {
					let pend_trans = [];
					let has_rate = true;

					if(this.editedItem.disbursement === 0 || this.editedItem.cash_receipt === 0 || this.editedItem.general_journal === 0){
						let rate = this.dataDollarRate.filter(e => e.fiscal_year == this.editedItem.fiscal_year && e.period_no == this.editedItem.period_no).length;
						if(rate === 0){
							has_rate = false;
						}
					}
					if(this.editedItem.disbursement === 0){
						let pend = this.getPendingTrans('DM','Disbursement');
						if(pend !== ''){
							pend_trans.push(pend); 
						}
					}
					if(this.editedItem.general_journal === 0){
						let pend = this.getPendingTrans('JE','General Journal');
						if(pend !== ''){
							pend_trans.push(pend); 
						}
					}
					if(this.editedItem.cash_receipt === 0){
						let pend = this.getPendingTrans('CR','Cash Receipt');
						if(pend !== ''){
							pend_trans.push(pend); 
						}
					}
					let count = pend_trans.length;
					if(count > 0){
						switch (count) {
						case 3:
							error = 'There are pending transactions in ' + pend_trans[0] + ', '  + pend_trans[1] + ' and ' + pend_trans[2] + '.';
							break;
						case 2:
							error = 'There are pending transactions in ' + pend_trans[0] + ' and ' + pend_trans[1] + '.';
							break;
            
						default:
							error = 'There are pending transactions in ' + pend_trans[0] + '.';
							break;
						}
						this.$swal.fire({
							type: 'error',
							title: 'Unable to closed this Period!',
							html: error
						});

					} else if(!has_rate){
						this.$swal.fire({
							type: 'error',
							title: 'Unable to closed this Period!',
							html: 'Please create Dollar Rate for this Period.'
						});
					}
					else {
						this.$store.dispatch('acctperiodmain/updateAcctperiodmain',this.editedItem);
					}
				} else {
					this.$store.dispatch('acctperiodmain/saveAcctperiodmain',this.editedItem);
				}
				setTimeout(() => {
					this.close();
				}, 3000);
			}
		},
		getPendingTrans(trans_type, trans_name){
			let name = '';
			this.trxdisbs.forEach(trx => {
				let date = format(new Date(trx.date_encoded), 'yyyy-MM-dd');
				let month = getMonth(parseISO(date)) + 1;
				let year = getYear(parseISO(date));
				if(trans_type === 'DM'){
					if((trx.document_type === 'DM' || trx.document_type === 'OB'|| trx.document_type === 'CW'|| trx.document_type === 'CV') && month == this.editedItem.period_no && year === this.editedItem.fiscal_year){
						if(trx.status != 7 && trx.status != 10 && trx.status != 20 ){
							name = trans_name;
						}
					}
				}else{
					if(trx.document_type === trans_type && month == this.editedItem.period_no && year === this.editedItem.fiscal_year){
						if(trx.status != 7 && trx.status != 10 && trx.status != 20 ){
							name = trans_name;
						}
					}
				}
			});
			return name;
		},
		async getDataDollarRate() {
			this.dataDollarRate = this.dollarrates.data;
			this.nextPageDollarRate= this.dollarrates.next_page_url;
			let page = 2;

			while (this.nextPageDollarRate !== null){
				await this.$store.dispatch('dollarrate/getData', page).then(response => {
					this.nextPageDollarRate = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDollarRate.push(details);
					});
				});
				page++;
			}
		},
	}
};
</script>